import React, { useEffect, useState } from 'react'
import { TextField, CircularProgress, InputAdornment } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useCheckEmailExist } from 'hooks/api/userHooks'

/* eslint-disable react/jsx-props-no-spreading */
export function EmailField({ initialEmail = '', required = false }) {
  const [isChecking, setIsChecking] = useState(false)
  const {
    setError,
    clearErrors,
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext()

  const email = watch('email')
  const { trigger: checkEmailExist } = useCheckEmailExist(email)

  useEffect(() => {
    const handleEmailExistenceCheck = async () => {
      if (email && email !== initialEmail) {
        setIsChecking(true)
        try {
          const data = await checkEmailExist()
          setIsChecking(false)
          if (data.email_exists) {
            setError('email', {
              type: 'manual',
              message: 'Email already taken',
            })
          } else if (!errors.email) {
            clearErrors('email')
          }
        } catch {
          setIsChecking(false)
          setError('email', {
            type: 'manual',
            message: 'Email already taken',
          })
        }
      }
    }

    const typingTimeout = setTimeout(handleEmailExistenceCheck, 300)

    return () => clearTimeout(typingTimeout)
  }, [
    email,
    initialEmail,
    setError,
    clearErrors,
    checkEmailExist,
    errors.email,
  ])

  useEffect(() => {
    setValue('email', initialEmail)
  }, [initialEmail, setValue])

  const handleEmailChange = async (e) => {
    const newEmail = e.target.value.replace(/\s+/g, '_').toLowerCase()
    setValue('email', newEmail)
    clearErrors('email')
    const isValid = await trigger('email')

    if (isValid) {
      setTimeout(() => {
        checkEmailExist()
      }, 300)
    }
  }

  return (
    <TextField
      required={required}
      fullWidth
      error={!!errors.email}
      helperText={errors.email ? errors.email.message : ''}
      label={required ? 'Email' : 'Email (optional)'}
      value={email}
      InputProps={{
        readOnly: watch('readOnly'),
        endAdornment: isChecking && (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ),
      }}
      {...register('email', {
        pattern: {
          value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
          message: 'Invalid email address',
        },
      })}
      onChange={handleEmailChange}
    />
  )
}
