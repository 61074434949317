import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, TextField } from '@mui/material'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'

import { getUtcTime } from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function UpdateTournamentInfo() {
  const { register, watch, control } = useFormContext()
  const { errors } = useFormContext().formState

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required={!watch('readOnly')}
          style={{ width: '100%' }}
          error={!!errors.name}
          label="Tournament name"
          InputProps={{
            readOnly: watch('readOnly'),
          }}
          {...register('name', {
            required: true,
          })}
        />
      </Grid>

      <Grid container spacing={2} item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} md={6}>
            <CustomDatePicker
              sx={{ width: '100%' }}
              label="Tournament date *"
              control={control}
              name="startTime"
              value={getUtcTime(watch('startTime'))}
              ignoreValidation
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CustomTimePicker
              sx={{ width: '100%' }}
              label="Start Time *"
              control={control}
              name="startTime"
              value={getUtcTime(watch('startTime'))}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <CustomTimePicker
              sx={{ width: '100%' }}
              label="End time *"
              control={control}
              name="endTime"
              value={getUtcTime(watch('endTime'))}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          error={!!errors.description}
          label="Description"
          multiline
          {...register('description')}
          InputProps={{
            readOnly: watch('readOnly'),
          }}
        />
      </Grid>
    </Grid>
  )
}
