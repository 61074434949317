import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { QRCodeSVG } from 'qrcode.react'

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  Tab,
  Typography,
  CardMedia,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import ListAltIcon from '@mui/icons-material/ListAlt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CloseIcon from '@mui/icons-material/Close'

import {
  MainWrapper,
  MainWrapperViewMode,
} from 'components/layout/MainWrapper/MainWrapper'
import { RoundRobinLeaderboardTable } from 'components/LeaderboardTable/LeaderboardTable'
import {
  TournamentRegisteredTeams,
  TournamentRegisteredWaitlistTeams,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { DeleteTournamentDialog } from 'components/DeleteTournamentDialog/DeleteTournamentDialog'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { UpdateTournamentInfo } from 'components/TournamentInfo/UpdateTournamentInfo'
import {
  RoundCourt,
  RoundCourtViewMode,
  PlayersSkippingRound,
} from 'components/RoundCourt/RoundCourt'

import {
  useRoundRobinTeamLeaderboard,
  useFinishTournament,
  useUpdateTournament,
  usePseudoDeleteTournament,
  useIncreaseTournamentCycleCount,
  useGetTournamentFocus,
  useSetTournamentFocus,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { useCurrentUser } from 'hooks/cookies'

import {
  isAdmin,
  isOrganizationAdmin,
  getPlayersWhoSkipARound,
  getUtcTime,
} from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function RoundRobinTeamInProgress({ data: tournamentData, mutate }) {
  const navigate = useNavigate()

  const { themeMode } = useThemeSwitcher()
  const currentUrl = window.location.href

  const { organization_id, teams, waitlist_teams, left_users, schedule } =
    tournamentData

  const score_type = 'games'

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)

  const { data: organizationData } = useOrganization(organization_id)
  const { data: tournamentFocusData } = useGetTournamentFocus(tournamentId)
  const {
    data: tournamentLeaderboardData,
    loading: tournamentLeaderboardLoading,
    mutate: mutateLeaderboard,
  } = useRoundRobinTeamLeaderboard(tournamentId)

  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin = isOrganizationAdmin(
    currentUser,
    tournamentData?.organization_id
  )
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const [currentRoundNumber, setCurrentRoundNumber] = useState(
    tournamentFocusData?.round_number || 0
  )

  const [updatedCurrentRoundNumber, setUpdatedCurrentRoundNumber] =
    useState(null)

  const userCurrentRoundNumber =
    !adminOrOrganizationAdmin && updatedCurrentRoundNumber !== null
      ? updatedCurrentRoundNumber
      : currentRoundNumber

  useEffect(() => {
    if (tournamentFocusData?.round_number !== undefined) {
      setCurrentRoundNumber(tournamentFocusData.round_number)
    }
  }, [tournamentFocusData])

  useEffect(() => {
    if (tournamentData) {
      mutateLeaderboard()
    }
  }, [tournamentData])

  const [openFinishTournamentModal, setOpenFinishTournamentModal] =
    useState(false)
  const [openDeleteTournamentModal, setOpenDeleteTournamentModal] =
    useState(false)
  const [openIncreaseCycleCountModal, setOpenIncreaseCycleCountModal] =
    useState(false)
  const [tabNumber, setTabValue] = useState('1')

  const [errorMessage, setErrorMessage] = useState('')

  const playersWhoSkipARound = getPlayersWhoSkipARound({
    round: schedule?.rounds[userCurrentRoundNumber],
    teams,
  })
  const playersWhoSkipANextRound = schedule?.rounds[userCurrentRoundNumber + 1]
    ? getPlayersWhoSkipARound({
        round: schedule?.rounds[userCurrentRoundNumber + 1],
        teams,
      })
    : []

  const [isViewMode, setIsViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem('isViewMode')
    return savedViewMode === 'true'
  })
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    localStorage.setItem('isViewMode', isViewMode.toString())
  }, [isViewMode])

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true)
          setUpdatedCurrentRoundNumber(null)
        })
        .catch((err) => {
          console.error(
            'Error attempting to enable full-screen mode:',
            err.message
          )
        })
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false)
        })
        .catch((err) => {
          console.error(
            'Error attempting to exit full-screen mode:',
            err.message
          )
        })
    }
  }

  const toggleViewMode = () => {
    setIsViewMode((prevMode) => !prevMode)
    handleToggleFullScreen()
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      organization_name: tournamentData?.organization_name || '',
      readOnly: true,
      type: tournamentData?.type || 'round_robin_team',
      name: tournamentData?.name || '',
      startTime: tournamentData?.start_time
        ? getUtcTime(tournamentData?.start_time)
        : getUtcTime(Date.now()),
      endTime: tournamentData?.end_time
        ? getUtcTime(tournamentData?.end_time)
        : getUtcTime(Date.now()),
      description: tournamentData?.description || '',
      maxUsers: tournamentData?.max_users || 0,
      cycleCount: tournamentData?.cycle_count || 1,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      cycle_count: watch('cycleCount'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        if (typeof currentValues[key] === 'number') {
          acc[key] = Number(currentValues[key])
        } else {
          acc[key] = currentValues[key]
        }
      }
      return acc
    }, {})

    return changes
  }

  const handleChangeTab = (_, tabNumber) => {
    setTabValue(tabNumber)
  }

  const { trigger: finishTournamentTrigger } = useFinishTournament()
  const { trigger: updateTournamentTrigger } = useUpdateTournament()
  const { trigger: pseudoDeleteTournamentTrigger } = usePseudoDeleteTournament()
  const { trigger: increaseCycleCountTrigger } =
    useIncreaseTournamentCycleCount()
  const { trigger: setTournamentFocusTrigger } = useSetTournamentFocus()

  if (tournamentLeaderboardLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  const handleFinishTournament = (tournamentId) => {
    finishTournamentTrigger(
      {
        id: parseInt(tournamentId, 10),
      },
      {
        onSuccess: () => {
          mutate()
          window.location.reload()
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: Number(tournamentId),
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handlePseudoDeleteTournament = () => {
    const tournamentIDInt = parseInt(tournamentId, 10)

    pseudoDeleteTournamentTrigger(
      {
        id: tournamentIDInt,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
      }
    )
  }

  const handleOpenFinishTournamentModal = () => {
    setOpenFinishTournamentModal(true)
  }

  const handleOpenDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(true)
  }

  const handleCloseFinishTournamentModal = () => {
    setOpenFinishTournamentModal(false)
  }

  const handleCloseDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(false)
  }

  const handleConfirmFinishTournament = (tournamentId) => {
    handleFinishTournament(tournamentId)
    setOpenFinishTournamentModal(false)
  }

  const handleConfirmDeleteTournament = () => {
    handlePseudoDeleteTournament()
    setOpenDeleteTournamentModal(false)
  }

  const handleMoreRoundsClick = () => {
    setOpenIncreaseCycleCountModal(true)
  }

  const handleCloseConfirmIncreaseCycleCountDialog = () => {
    setOpenIncreaseCycleCountModal(false)
  }

  const handleIncreaseCycleCount = () => {
    increaseCycleCountTrigger(
      { id: tournamentIdInt },
      {
        onSuccess: () => {
          mutate().then((updatedData) => {
            if (
              updatedData &&
              updatedData.schedule &&
              updatedData.schedule.rounds &&
              updatedData.schedule.rounds.length >= userCurrentRoundNumber
            ) {
              setCurrentRoundNumber((prev) => prev + 1)
            }
          })
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleConfirmIncreaseCycleCount = () => {
    handleIncreaseCycleCount()
    setOpenIncreaseCycleCountModal(false)
  }

  const handleMutateData = () => {
    mutate()
    mutateLeaderboard()
  }

  const handleAdminPrevRound = () => {
    setTournamentFocusTrigger(
      {
        tournament_id: tournamentIdInt,
        round_number: userCurrentRoundNumber - 1,
      },
      {
        onSuccess: () => {
          setCurrentRoundNumber(userCurrentRoundNumber - 1)
        },
      }
    )
  }

  const handleAdminNextRound = () => {
    setTournamentFocusTrigger(
      {
        tournament_id: tournamentIdInt,
        round_number: userCurrentRoundNumber + 1,
      },
      {
        onSuccess: () => {
          setCurrentRoundNumber(userCurrentRoundNumber + 1)
        },
      }
    )
  }

  const handleUserPrevRound = () => {
    if (updatedCurrentRoundNumber !== null) {
      setUpdatedCurrentRoundNumber((prev) => prev - 1)
    } else {
      setUpdatedCurrentRoundNumber(userCurrentRoundNumber - 1)
    }
  }

  const handleUserNextRound = () => {
    if (updatedCurrentRoundNumber !== null) {
      setUpdatedCurrentRoundNumber((prev) => prev + 1)
    } else {
      setUpdatedCurrentRoundNumber(userCurrentRoundNumber + 1)
    }
  }

  return isViewMode ? (
    <MainWrapperViewMode>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ position: 'relative' }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ position: 'relative' }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box ml={4}>
              <QRCodeSVG value={currentUrl} size={100} />
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                image={
                  themeMode === 'dark'
                    ? '/logo_dark_theme.png'
                    : '/logo_dark_theme.png'
                }
                alt="logo"
                sx={{ maxWidth: '700px', height: 'auto', ml: 2 }}
              />
              <CardMedia
                component="img"
                image={
                  themeMode === 'dark'
                    ? '/mascot_dark_theme.png'
                    : '/mascot_light_theme.png'
                }
                alt="mascot"
                sx={{ maxWidth: '120px', height: 'auto', ml: 2 }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            pr={4}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <CloseIcon
              onClick={() => toggleViewMode()}
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2} alignItems="flex-start">
          <Grid item md={0.25} />

          <Grid item md={3} container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h4">{tournamentData?.name}</Typography>
            </Grid>

            <Grid item xs={12} mt={4}>
              <RoundRobinLeaderboardTable
                leaderboard={tournamentLeaderboardData}
                themeMode={themeMode}
                viewMode
                onlyPoints
              />
            </Grid>
          </Grid>

          <Grid item md={0.25} />

          <Grid item md={4} container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h4">
                {`Round: ${userCurrentRoundNumber + 1} / ${
                  schedule.rounds.length
                }`}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              mt={2}
              justifyContent="center"
            >
              {schedule.rounds[userCurrentRoundNumber].courts.map(
                (_, courtNumber) => (
                  <RoundCourtViewMode
                    key={courtNumber}
                    roundNumber={userCurrentRoundNumber}
                    courtNumber={courtNumber}
                    tournamentData={tournamentData}
                    themeMode={themeMode}
                  />
                )
              )}
            </Grid>

            <Grid item xs={12} mt={2}>
              <PlayersSkippingRound
                playersWhoSkipARound={playersWhoSkipARound}
                themeMode={themeMode}
                viewMode
              />
            </Grid>
          </Grid>

          <Grid item md={0.25} />

          {schedule.rounds[userCurrentRoundNumber + 1] && (
            <Grid item md={4} container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h4">Next Round</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                spacing={2}
                mt={2}
                justifyContent="center"
              >
                {schedule.rounds[userCurrentRoundNumber].courts.map(
                  (_, courtNumber) => (
                    <RoundCourtViewMode
                      key={courtNumber}
                      roundNumber={userCurrentRoundNumber + 1}
                      courtNumber={courtNumber}
                      tournamentData={tournamentData}
                      themeMode={themeMode}
                    />
                  )
                )}
              </Grid>

              <Grid item xs={12} mt={2}>
                <PlayersSkippingRound
                  playersWhoSkipARound={playersWhoSkipANextRound}
                  themeMode={themeMode}
                  viewMode
                />
              </Grid>
            </Grid>
          )}

          <Grid item md={0.25} />
        </Grid>
      </Grid>
    </MainWrapperViewMode>
  ) : (
    <MainWrapper errorMessage={errorMessage} setErrorMessage={setErrorMessage}>
      <FormProvider
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        formState={{ errors }}
        watch={watch}
        reset={reset}
        setValue={setValue}
      >
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUpdateTournament)}
          fullWidth
        >
          <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={4} mt={2} mb={2} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">{watch('name')}</Typography>
          </Grid>

          <Container maxWidth="md" sx={{ padding: 0 }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabNumber}>
                <Container
                  sx={{
                    '@media (min-width: 0px)': {
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab}>
                      <Tab
                        label="Rounds"
                        value="1"
                        sx={{ paddingLeft: 0 }}
                        icon={<ListAltIcon />}
                        iconPosition="start"
                      />
                      <Tab
                        label="Leaderboard"
                        value="2"
                        icon={<EmojiEventsIcon />}
                        iconPosition="start"
                      />
                      <Tab
                        label="Info"
                        value="3"
                        icon={<InfoIcon />}
                        iconPosition="start"
                      />
                    </TabList>
                  </Box>
                </Container>

                <TabPanel value="1" sx={{ padding: 0 }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Grid
                      item
                      container
                      spacing={2}
                      xs={12}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Grid
                        item
                        md={5}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      />
                      <Grid
                        item
                        md={2}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      >
                        <Button
                          onClick={toggleViewMode}
                          variant="contained"
                          style={{ width: '100%' }}
                        >
                          TV Mode
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />

                  <Grid mt={{ xs: 0, md: 0 }} container spacing={2} mb={4}>
                    <Grid item xs={12} mt={2} sx={{ textAlign: 'center' }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={3} sx={{ textAlign: 'left' }}>
                          <ArrowBackIcon
                            onClick={() => {
                              if (adminOrOrganizationAdmin) {
                                handleAdminPrevRound()
                              } else {
                                handleUserPrevRound()
                              }
                            }}
                            disabled={userCurrentRoundNumber === 0}
                            style={{
                              cursor: 'pointer',
                              visibility:
                                userCurrentRoundNumber === 0
                                  ? 'hidden'
                                  : 'visible',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'center' }}>
                          <Typography variant="h5">
                            {`Round: ${userCurrentRoundNumber + 1} / ${
                              schedule.rounds.length
                            }`}
                          </Typography>
                        </Grid>
                        {adminOrOrganizationAdmin &&
                        userCurrentRoundNumber + 1 >= schedule.rounds.length ? (
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              visibility: adminOrOrganizationAdmin
                                ? 'visible'
                                : 'hidden',
                            }}
                          >
                            <Button
                              onClick={handleMoreRoundsClick}
                              type="button"
                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                              sx={{
                                minWidth: 'auto',
                              }}
                            >
                              More Rounds
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item xs={3} sx={{ textAlign: 'right' }}>
                            <ArrowForwardIcon
                              onClick={() => {
                                if (adminOrOrganizationAdmin) {
                                  handleAdminNextRound()
                                } else {
                                  handleUserNextRound()
                                }
                              }}
                              disabled={
                                userCurrentRoundNumber + 1 >=
                                schedule.rounds.length
                              }
                              style={{
                                cursor: 'pointer',
                                visibility:
                                  userCurrentRoundNumber + 1 >=
                                  schedule.rounds.length
                                    ? 'hidden'
                                    : 'visible',
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="center">
                        {schedule.rounds[userCurrentRoundNumber].courts.map(
                          (_, courtNumber) => (
                            <RoundCourt
                              key={courtNumber}
                              isAdmin={adminOrOrganizationAdmin}
                              roundNumber={userCurrentRoundNumber}
                              courtNumber={courtNumber}
                              tournamentData={tournamentData}
                              mutateData={handleMutateData}
                              scoreType={score_type}
                              setErrorMessage={setErrorMessage}
                              themeMode={themeMode}
                            />
                          )
                        )}
                      </Grid>

                      <Grid item xs={12} mt={2}>
                        <PlayersSkippingRound
                          playersWhoSkipARound={playersWhoSkipARound}
                          themeMode={themeMode}
                        />
                      </Grid>
                    </Grid>

                    {adminOrOrganizationAdmin && (
                      <>
                        <Grid
                          item
                          md={4}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                        <Grid item xs={12} md={4}>
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => {
                              handleOpenFinishTournamentModal()
                            }}
                            type="button"
                            color="success"
                            disabled={false}
                            endIcon={<CheckCircleIcon />}
                            variant="contained"
                          >
                            Finish tournament
                          </Button>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                      </>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value="2" sx={{ padding: 0 }}>
                  <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} mt={2}>
                      <RoundRobinLeaderboardTable
                        leaderboard={tournamentLeaderboardData}
                        themeMode={themeMode}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="3" sx={{ padding: 0 }}>
                  {watch('readOnly') ? (
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} mt={2}>
                        <TournamentInfo
                          tournamentData={tournamentData}
                          organizationData={organizationData}
                          themeMode={themeMode}
                        />
                      </Grid>

                      {adminOrOrganizationAdmin && (
                        <>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                          <Grid item xs={12} md={4}>
                            <Button
                              fullWidth
                              onClick={() => setValue('readOnly', false)}
                              type="button"
                              startIcon={<EditIcon />}
                              variant="contained"
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                        </>
                      )}

                      <Grid item xs={12} md={12}>
                        <TournamentRegisteredTeams
                          onlySwapActions
                          playerCanInteract={false}
                          teams={teams}
                          waitlistTeams={waitlist_teams}
                          maxTeams={teams.length}
                          isAdmin={adminOrOrganizationAdmin}
                          currentUser={currentUser}
                          tournamentId={tournamentIdInt}
                          organizationId={organization_id}
                          exceptionIds={[
                            ...teams.flatMap((team) =>
                              team.users.map((u) => u.id)
                            ),
                            ...waitlist_teams.flatMap((team) =>
                              team.users.map((u) => u.id)
                            ),
                          ]}
                          themeMode={themeMode}
                        />
                      </Grid>

                      {waitlist_teams?.length > 0 && (
                        <Grid item xs={12} md={12}>
                          <TournamentRegisteredWaitlistTeams
                            readOnly
                            waitlistTeams={waitlist_teams}
                            isAdmin={adminOrOrganizationAdmin}
                            currentUser={currentUser}
                            themeMode={themeMode}
                          />
                        </Grid>
                      )}

                      {left_users && left_users.length > 0 && (
                        <Grid item xs={12} md={12}>
                          <TournamentRegisteredLeftPlayers
                            leftPlayers={left_users}
                            themeMode={themeMode}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} mt={2}>
                        <UpdateTournamentInfo />
                      </Grid>

                      {adminOrOrganizationAdmin && (
                        <>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                          <Grid item xs={12} md={4}>
                            <Button
                              color="error"
                              fullWidth
                              onClick={handleOpenDeleteTournamentModal}
                              variant="contained"
                            >
                              Delete tournament
                            </Button>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />

                          <Grid item xs={2} />
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              onClick={handleCancelUpdate}
                              type="button"
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              type="submit"
                              disabled={!Object.keys(collectChanges()).length}
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item xs={2} />
                        </>
                      )}
                    </Grid>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </FormControl>
      </FormProvider>

      <Dialog
        open={openFinishTournamentModal}
        onClose={handleCloseFinishTournamentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to finish the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Once finished, the following will be locked:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">- Rounds results</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                - You won&apos;t be able to delete the tournament
              </Typography>
            </ListItem>
          </List>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFinishTournamentModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmFinishTournament(tournamentId)}
            color="primary"
            variant="contained"
            autoFocus
          >
            Finish Tournament
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openIncreaseCycleCountModal}
        onClose={handleCloseConfirmIncreaseCycleCountDialog}
      >
        <DialogTitle>
          Do you want to add more rounds to the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Its can&apos;t be undone, but you can finish tournament in any
            moment
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmIncreaseCycleCountDialog}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmIncreaseCycleCount}
            color="success"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteTournamentDialog
        openDeleteTournamentModal={openDeleteTournamentModal}
        handleCloseDeleteTournamentModal={handleCloseDeleteTournamentModal}
        handleConfirmDeleteTournament={handleConfirmDeleteTournament}
      />
    </MainWrapper>
  )
}
