import { Cookies } from 'react-cookie'
import { jwtDecode } from 'jwt-decode'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const cookies = new Cookies()

dayjs.extend(utc)
dayjs.extend(timezone)

export const getUtcTime = (time) => dayjs(time).utc()

export function getCurrentUser() {
  const userCookie = cookies.get('user')
  return userCookie ? JSON.parse(atob(userCookie)) : undefined
}

export function isAdmin(user) {
  return user && user.is_admin
}

export function isOrganizationAdmin(user, organizationId) {
  return (
    user?.administered_organizations?.data?.some(
      (organization) => organization.id === organizationId
    ) || false
  )
}

export function capitalizeWords(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function getValidUrl(url) {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`
  }
  return url
}

export function getOrdinalSuffix(number) {
  if (number % 10 === 1 && number % 100 !== 11) {
    return 'st'
  }
  if (number % 10 === 2 && number % 100 !== 12) {
    return 'nd'
  }
  if (number % 10 === 3 && number % 100 !== 13) {
    return 'rd'
  }
  return 'th'
}

export function tournamentPlaceOrMedal(tournamentPlace) {
  if (tournamentPlace === 1) {
    return '🥇'
  }
  if (tournamentPlace === 2) {
    return '🥈'
  }
  if (tournamentPlace === 3) {
    return '🥉'
  }

  return tournamentPlace + getOrdinalSuffix(tournamentPlace)
}

export function getUserPlaceInTournament(leaderboard, userId) {
  const userPlace = leaderboard.find((user) => user.user_id === userId)
  return userPlace ? tournamentPlaceOrMedal(userPlace.place) : null
}

export function getPlayersWhoSkipARound({ round, teams = [], users = [] }) {
  const allPlayers =
    teams.length > 0
      ? teams.flatMap((team) => team.users).map((user) => user.id)
      : users.map((user) => user.id)

  const playersInRound = round.courts
    .flatMap((court) => court.teams)
    .flatMap((team) => team.players)
    .map((player) => player.id)

  const playersWhoSkip = allPlayers.filter(
    (playerId) => !playersInRound.includes(playerId)
  )

  return teams.length > 0
    ? teams
        .flatMap((team) => team.users)
        .filter((user) => playersWhoSkip.includes(user.id))
    : users.filter((user) => playersWhoSkip.includes(user.id))
}

export function prepareCourtsForUpdate(courts) {
  return courts.map((court) => {
    const { id, name, court_type, walls_type, size, is_deleted } = court

    if (id && id.toString().startsWith('temp-')) {
      return { name, court_type, walls_type, size }
    }

    if (is_deleted) {
      return { id, is_deleted }
    }

    return { id, name, court_type, walls_type, size }
  })
}

export const getUserRoleIdFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token)
    try {
      const role_id = parseInt(decodedToken.role_id, 10)
      return role_id
    } catch (error) {
      console.error('Failed to parse role_id from JWT token:', error)
      return null
    }
  } catch (error) {
    console.error('Failed to decode JWT token:', error)
    return null
  }
}

export function refreshJwtAndFetchPolicies(
  refreshTokenTrigger,
  allRolePoliciesTrigger,
  handleRedirect
) {
  if (cookies.get('user') && cookies.get('jwt')) {
    refreshTokenTrigger(
      {},
      {
        onSuccess: () => {
          allRolePoliciesTrigger(
            {},
            {
              onSuccess: (data) => {
                if (data.allowed_policies) {
                  localStorage.setItem(
                    'allowedPolicies',
                    JSON.stringify(data.allowed_policies)
                  )
                }
                if (data.discarded_policies) {
                  localStorage.setItem(
                    'discardedPolicies',
                    JSON.stringify(data.discarded_policies)
                  )
                }
              },
            }
          )
        },
        onError: () => handleRedirect('/login'),
      }
    )
  }
}

export function isMethodAllowed(method, route) {
  const allowedPolicies =
    JSON.parse(localStorage.getItem('allowedPolicies')) || []

  return allowedPolicies.some((policy) => {
    const policyRoute = policy.route.replace(/{.*?}/g, '.*')
    const regex = new RegExp(`^${policyRoute}$`)
    return policy.method === method && regex.test(route)
  })
}

export function encodeToBase64(str) {
  return btoa(unescape(encodeURIComponent(str)))
}

export function decodeFromBase64(str) {
  return decodeURIComponent(escape(atob(str)))
}

export function countMinimumPlayersNeeded(courts) {
  return courts.reduce((acc, court) => {
    if (court.type === 'single') {
      return acc + 2
    }
    return acc + 4
  }, 0)
}
