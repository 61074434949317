import React, { useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'

/* eslint-disable react/jsx-props-no-spreading */
export function GenderField({ initialGender = 'male' }) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setValue('gender', initialGender)
  }, [initialGender, setValue])

  return (
    <FormControl fullWidth error={!!errors.gender}>
      <InputLabel id="gender-label">Gender</InputLabel>
      <Controller
        name="gender"
        control={control}
        rules={{
          required: 'Gender is required',
        }}
        render={({ field }) => (
          <Select
            labelId="gender-label"
            label="Gender"
            {...field}
            value={field.value || initialGender}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        )}
      />
      {errors.gender && (
        <FormHelperText>{errors.gender.message}</FormHelperText>
      )}
    </FormControl>
  )
}
