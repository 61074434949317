import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { deleteFetcher, getFetcher, postFetcher, putFetcher } from './fetchers'

export function useAllUsers(limit = 10, offset = 0, is_deleted = false) {
  const searchParams = new URLSearchParams()

  const params = { limit, offset, is_deleted }

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null) searchParams.append(key, value)
  })

  return useSWR(`/user?${searchParams}`, getFetcher)
}

export function useUserById(id) {
  return useSWR(`/user/${id}`, getFetcher)
}

export function useUserStatisticById(id) {
  return useSWR(`/user/${id}/statistic`, getFetcher)
}

export function useUserFinishedTournaments(id) {
  return useSWR(`/user/${id}/finished_tournaments`, getFetcher)
}

export function useCreateUser() {
  return useSWRMutation('/user', postFetcher)
}

export function useUpdateUser() {
  return useSWRMutation('/user/', putFetcher)
}

export function useSearchUsers({
  searchString,
  limit = 10,
  offset = 0,
  organizationId = null,
  is_deleted = null,
}) {
  const params = { limit, offset }
  if (searchString) {
    params.search_string = searchString
  }
  if (organizationId) {
    params.organization_id = organizationId
  }

  if (is_deleted !== null) {
    params.is_deleted = is_deleted
  }

  const searchParams = new URLSearchParams(params)
  return useSWRMutation(`/user?${searchParams}`, getFetcher)
}

export function useCheckUsernameExist(username) {
  const searchParams = new URLSearchParams({ username })

  return useSWRMutation(
    `/user/check_username_exist?${searchParams}`,
    getFetcher
  )
}

export function useCheckEmailExist(email) {
  const searchParams = new URLSearchParams({ email })

  return useSWRMutation(`/user/check_email_exist?${searchParams}`, getFetcher)
}

export function useUploadAvatar() {
  const { trigger, ...mutationState } = useSWRMutation(
    '/user/avatar',
    postFetcher
  )

  return {
    uploadAvatar: async (file) => {
      const formData = new FormData()
      formData.append('avatar', file)
      return trigger(formData)
    },
    ...mutationState,
  }
}

export function useResetAvatar() {
  return useSWRMutation('/user/avatar', deleteFetcher)
}
