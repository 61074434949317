import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { getFetcher, postFetcher, putFetcher, deleteFetcher } from './fetchers'

export function useTournaments({
  user_id = null,
  organization_id = null,
  status = null,
  before_date = null,
  after_date = null,
  order = null,
  limit = 10,
  offset = 0,
  is_deleted = false,
}) {
  const searchParams = new URLSearchParams()

  const params = {
    user_id,
    organization_id,
    status,
    before_date,
    after_date,
    order,
    limit,
    offset,
    is_deleted,
  }

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null) searchParams.append(key, value)
  })

  return useSWR(`/tournament?${searchParams.toString()}`, getFetcher)
}

export function useTournament(tournamentId, refreshInterval = 5000) {
  return useSWR(`/tournament/${tournamentId}`, getFetcher, { refreshInterval })
}

export function useTournamentType(tournamentId) {
  return useSWR(`/tournament/${tournamentId}/type`, getFetcher)
}

export function useAmericanoSingle(tournamentId, refreshInterval = 2000) {
  return useSWR(`/americano_single/${tournamentId}`, getFetcher, {
    refreshInterval,
  })
}

export function useAmericanoSingleLeaderboard(tournamentId) {
  return useSWR(`/americano_single/${tournamentId}/leaderboard`, getFetcher)
}

export function useAmericanoTeam(tournamentId, refreshInterval = 2000) {
  return useSWR(`/americano_team/${tournamentId}`, getFetcher, {
    refreshInterval,
  })
}

export function useAmericanoTeamLeaderboard(tournamentId) {
  return useSWR(`/americano_team/${tournamentId}/leaderboard`, getFetcher)
}

export function useRoundRobinTeam(tournamentId, refreshInterval = 2000) {
  return useSWR(`/round_robin_team/${tournamentId}`, getFetcher, {
    refreshInterval,
  })
}

export function useRoundRobinTeamLeaderboard(tournamentId) {
  return useSWR(`/round_robin_team/${tournamentId}/leaderboard`, getFetcher)
}

export function useCreateTournament() {
  return useSWRMutation('/tournament', postFetcher)
}

export function useUpdateTournament() {
  return useSWRMutation('/tournament', putFetcher)
}

export function usePseudoDeleteTournament() {
  return useSWRMutation('/tournament/pseudo_delete', deleteFetcher)
}

export function useStartTournament() {
  return useSWRMutation('/tournament/start', postFetcher)
}

export function useAddUserToTournament() {
  return useSWRMutation('/tournament/user', postFetcher)
}

export function useAddUserToTournamentWaitlist() {
  return useSWRMutation('/tournament/user/waitlist', postFetcher)
}

export function useRemoveUserFromTournament() {
  return useSWRMutation('/tournament/user', deleteFetcher)
}

export function useRemoveUserFromTournamentWaitlist() {
  return useSWRMutation('/tournament/user/waitlist', deleteFetcher)
}

export function useReplaceUserInTournament() {
  return useSWRMutation('/tournament/user/replace', postFetcher)
}

export function useAmericanoSingleRoundNumber() {
  return useSWR('/americano_single/round_number', getFetcher)
}

export function useAmericanoTeamRoundNumber() {
  return useSWR('/americano_team/round_number', getFetcher)
}

export function useTournamentRoundNumber(tournamentType) {
  return useSWR(`/tournament/round_number/${tournamentType}`, getFetcher)
}

export function useSetOrUpdateTournamentMatch() {
  return useSWRMutation('/tournament/match', postFetcher)
}

export function useResetTournamentMatch() {
  return useSWRMutation('/tournament/match/', deleteFetcher)
}

export function useIncreaseTournamentCycleCount() {
  return useSWRMutation('/tournament/increase_cycle_count', postFetcher)
}

export function useFinishTournament() {
  return useSWRMutation('/tournament/finish', postFetcher)
}

export function useGetTournamentFocus(tournamentId, refreshInterval = 2000) {
  return useSWR(`/tournament/${tournamentId}/focus`, getFetcher, {
    refreshInterval,
  })
}

export function useSetTournamentFocus() {
  return useSWRMutation('/tournament/focus', postFetcher)
}

export function useApproveTournamentUser() {
  return useSWRMutation('/tournament/user/approve', postFetcher)
}

export function useRejectTournamentUser() {
  return useSWRMutation('/tournament/user/reject', postFetcher)
}

export function useBackToPendingTournamentUser() {
  return useSWRMutation('/tournament/user/back_to_pending', postFetcher)
}
