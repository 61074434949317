import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Collapse,
  Alert,
} from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'

import styles from 'styles/main.module.scss'

import { useRegister } from 'hooks/api/authHooks'
import { useForceUpdateUserCookie } from 'hooks/cookies'
import { getCurrentUser } from 'utils'
import { UsernameField } from 'components/Fields/Username'
import { GenderField } from './Fields/Gender'
import { EmailField } from './Fields/Email'

/* eslint-disable react/jsx-props-no-spreading */
export function RegisterDialog({ callback, open, onClose }) {
  const [errorMessage, setErrorMessage] = useState('')

  const updateCookie = useForceUpdateUserCookie()
  const { trigger } = useRegister()

  const methods = useForm()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const handleRegister = (data) => {
    trigger(
      {
        name: data.name,
        surname: data.surname,
        username: data.username,
        gender: data.gender,
        phone: data.phone,
        email: data.email,
        password: data.password,
      },
      {
        onSuccess: (userData) => {
          updateCookie(userData)
          const user = getCurrentUser()

          onClose()
          callback(user)
        },
        onError: (e) => setErrorMessage(e.info.error),
      }
    )
  }

  return (
    <Dialog
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClose={onClose}
    >
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage('')
          }}
        >
          Error: {errorMessage}
        </Alert>
      </Collapse>

      <DialogContent>
        <FormProvider {...methods}>
          <FormControl
            component="form"
            onSubmit={handleSubmit(handleRegister)}
            fullWidth
          >
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  style={{ width: '100%' }}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                  label="Name"
                  {...register('name', { required: 'Name is required' })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  style={{ width: '100%' }}
                  label="Surname"
                  {...register('surname')}
                />
              </Grid>

              <Grid item xs={12}>
                <UsernameField />
              </Grid>

              <Grid item xs={12}>
                <EmailField />
              </Grid>

              <Grid item xs={12}>
                <GenderField />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  style={{ width: '100%' }}
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ''}
                  type="password"
                  label="Password"
                  {...register('password', {
                    required: 'Password is required',
                  })}
                />
              </Grid>

              <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={4}>
                <Button
                  style={{ width: '100%' }}
                  type="submit"
                  variant="contained"
                  disabled={Object.keys(errors).length > 0}
                >
                  Register
                </Button>
              </Grid>
              <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />

              <Grid item xs={12} textAlign="right">
                <Link to="/login" className={styles.greyLink}>
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>
          </FormControl>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
